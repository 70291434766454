export const currency_list = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    format: 'د.إ.{amount}',
    precision: 2,
    country_code: 'AE',
  },
  {
    code: 'AFN',
    name: 'Afghan Afghani',
    format: 'Af{amount}',
    precision: 2,
    country_code: 'AF',
  },
  {
    code: 'ALL',
    name: 'Albanian Lek',
    format: 'L{amount}',
    precision: 2,
    country_code: 'AL',
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    format: '֏{amount}',
    precision: 2,
    country_code: 'AM',
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    format: 'ƒ{amount}',
    precision: 2,
    country_code: 'CW',
  },
  {
    code: 'AOA',
    name: 'Angolan Kwanza',
    format: 'Kz{amount}',
    precision: 2,
    country_code: 'AO',
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    format: 'AR${amount}',
    precision: 2,
    country_code: 'AR',
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    format: 'AU${amount}',
    precision: 2,
    country_code: 'AU',
  },
  {
    code: 'AWG',
    name: 'Aruban Florin',
    format: 'ƒ{amount}',
    precision: 2,
    country_code: 'AW',
  },
  {
    code: 'AZN',
    name: 'Azerbaijani Manat',
    format: 'ман{amount}',
    precision: 2,
    country_code: 'AZ',
  },
  {
    code: 'BAM',
    name: 'Bosnia and Herzegovina Convertible Mark',
    format: 'KM{amount}',
    precision: 2,
    country_code: 'BA',
  },
  {
    code: 'BBD',
    name: 'Barbadian Dollar',
    format: 'BBD${amount}',
    precision: 2,
    country_code: 'BB',
  },
  {
    code: 'BDT',
    name: 'Bangladeshi Taka',
    format: '৳{amount}',
    precision: 2,
    country_code: 'BD',
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    format: 'лв.{amount}',
    precision: 2,
    country_code: 'BG',
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    format: 'BD{amount}',
    precision: 3,
    country_code: 'BH',
  },
  {
    code: 'BIF',
    name: 'Burundian Franc',
    format: 'FBu{amount}',
    precision: 2,
    country_code: 'BI',
  },
  {
    code: 'BMD',
    name: 'Bermudian Dollar',
    format: '${amount}',
    precision: 2,
    country_code: 'BM',
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    format: 'B${amount}',
    precision: 2,
    country_code: 'BN',
  },
  {
    code: 'BOB',
    name: 'Bolivian Boliviano',
    format: 'Bs.{amount}',
    precision: 2,
    country_code: 'BO',
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    format: 'R${amount}',
    precision: 2,
    country_code: 'BR',
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
    format: '${amount}',
    precision: 2,
    country_code: 'BS',
  },
  {
    code: 'BTN',
    name: 'Bhutanese Ngultrum',
    format: 'Nu.{amount}',
    precision: 2,
    country_code: 'BT',
  },
  {
    code: 'BWP',
    name: 'Botswana Pula',
    format: 'P{amount}',
    precision: 2,
    country_code: 'BW',
  },
  {
    code: 'BYN',
    name: 'Belarusian Ruble',
    format: 'Br{amount}',
    precision: 2,
    country_code: 'BY',
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    format: 'BZ${amount}',
    precision: 2,
    country_code: 'BZ',
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    format: 'CA${amount}',
    precision: 2,
    country_code: 'CA',
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
    format: 'FC{amount}',
    precision: 2,
    country_code: 'CD',
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    format: 'Fr.{amount}',
    precision: 2,
    country_code: 'CH',
  },
  {
    code: 'CKD',
    name: 'Cook Islands Dollar',
    format: '${amount}',
    precision: 2,
    country_code: 'CK',
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    format: 'CL${amount}',
    precision: 0,
    country_code: 'CL',
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
    format: 'CN¥{amount}',
    precision: 2,
    country_code: 'CN',
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    format: 'CO${amount}',
    precision: 2,
    country_code: 'CO',
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colon',
    format: '₡{amount}',
    precision: 2,
    country_code: 'CR',
  },
  {
    code: 'CUC',
    name: 'Cuban convertible Peso',
    format: 'CUC${amount}',
    precision: 2,
    country_code: 'CU',
  },
  {
    code: 'CUP',
    name: 'Cuban Peso',
    format: '$MN{amount}',
    precision: 2,
    country_code: 'CU',
  },
  {
    code: 'CVE',
    name: 'Cabo Verdean Escudo',
    format: 'CV${amount}',
    precision: 2,
    country_code: 'CV',
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    format: 'Kč{amount}',
    precision: 2,
    country_code: 'CZ',
  },
  {
    code: 'DJF',
    name: 'Djiboutian Franc',
    format: 'Fdj{amount}',
    precision: 2,
    country_code: 'DJ',
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    format: 'kr.{amount}',
    precision: 2,
    country_code: 'DK',
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    format: 'RD${amount}',
    precision: 2,
    country_code: 'DO',
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    format: 'DA{amount}',
    precision: 2,
    country_code: 'DZ',
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    format: 'E£{amount}',
    precision: 2,
    country_code: 'EG',
  },
  {
    code: 'ERN',
    name: 'Eritrean Nakfa',
    format: 'Nkf{amount}',
    precision: 2,
    country_code: 'ER',
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
    format: 'Br{amount}',
    precision: 2,
    country_code: 'ET',
  },
  {
    code: 'EUR',
    name: 'Euro',
    format: '€{amount}',
    precision: 2,
    country_code: 'EU',
  },
  {
    code: 'FJD',
    name: 'Fijian Dollar',
    format: 'FJ${amount}',
    precision: 2,
    country_code: 'FJ',
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
    format: 'FK£{amount}',
    precision: 2,
    country_code: 'FK',
  },
  {
    code: 'FOK',
    name: 'Faroese Króna',
    format: 'kr{amount}',
    precision: 2,
    country_code: 'FO',
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    format: '£{amount}',
    precision: 2,
    country_code: 'GB',
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    format: '₾{amount}',
    precision: 2,
    country_code: 'GE',
  },
  {
    code: 'GGP',
    name: 'Guernsey Pound',
    format: '£{amount}',
    precision: 2,
    country_code: 'GG',
  },
  {
    code: 'GHS',
    name: 'Ghanaian Cedi',
    format: 'GH₵{amount}',
    precision: 2,
    country_code: 'GH',
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
    format: '£{amount}',
    precision: 2,
    country_code: 'GI',
  },
  {
    code: 'GMD',
    name: 'Gambian Dalasi',
    format: 'D{amount}',
    precision: 2,
    country_code: 'GM',
  },
  {
    code: 'GNF',
    name: 'Guinean Franc',
    format: 'FG{amount}',
    precision: 2,
    country_code: 'GN',
  },
  {
    code: 'GTQ',
    name: 'Guatemalan Quetzal',
    format: 'Q{amount}',
    precision: 2,
    country_code: 'GT',
  },
  {
    code: 'GYD',
    name: 'Guyanese Dollar',
    format: 'G${amount}',
    precision: 2,
    country_code: 'GY',
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    format: 'HK${amount}',
    precision: 2,
    country_code: 'HK',
  },
  {
    code: 'HNL',
    name: 'Honduran Lempira',
    format: 'L{amount}',
    precision: 2,
    country_code: 'HN',
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
    format: 'kn{amount}',
    precision: 2,
    country_code: 'HR',
  },
  {
    code: 'HTG',
    name: 'Haitian Gourde',
    format: 'G{amount}',
    precision: 2,
    country_code: 'HT',
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    format: 'Ft{amount}',
    precision: 2,
    country_code: 'HU',
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    format: 'Rp{amount}',
    precision: 2,
    country_code: 'ID',
  },
  {
    code: 'ILS',
    name: 'Israeli new Shekel',
    format: '₪{amount}',
    precision: 2,
    country_code: 'IL',
  },
  {
    code: 'IMP',
    name: 'Manx Pound',
    format: '£{amount}',
    precision: 2,
    country_code: 'IM',
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    format: 'Rs.{amount}',
    precision: 2,
    country_code: 'IN',
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    format: 'د.ع.{amount}',
    precision: 3,
    country_code: 'IQ',
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
    format: '﷼{amount}',
    precision: 2,
    country_code: 'IR',
  },
  {
    code: 'ISK',
    name: 'Icelandic Krona',
    format: 'kr{amount}',
    precision: 2,
    country_code: 'IS',
  },
  {
    code: 'JEP',
    name: 'Jersey Pound',
    format: '£{amount}',
    precision: 2,
    country_code: 'JE',
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    format: 'J${amount}',
    precision: 2,
    country_code: 'JM',
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    format: 'JD{amount}',
    precision: 3,
    country_code: 'JO',
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    format: '¥{amount}',
    precision: 2,
    country_code: 'JP',
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    format: 'KSh{amount}',
    precision: 2,
    country_code: 'KE',
  },
  {
    code: 'KGS',
    name: 'Kyrgyzstani Som',
    format: 'с{amount}',
    precision: 2,
    country_code: 'KG',
  },
  {
    code: 'KHR',
    name: 'Cambodian Riel',
    format: '៛{amount}',
    precision: 2,
    country_code: 'KH',
  },
  {
    code: 'KID',
    name: 'Kiribati Dollar',
    format: '${amount}',
    precision: 2,
    country_code: 'KI',
  },
  {
    code: 'KMF',
    name: 'Comorian Franc',
    format: 'CF{amount}',
    precision: 2,
    country_code: 'KM',
  },
  {
    code: 'KPW',
    name: 'North Korean Won',
    format: '₩{amount}',
    precision: 2,
    country_code: 'KP',
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
    format: '₩{amount}',
    precision: 2,
    country_code: 'KR',
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    format: 'KD{amount}',
    precision: 3,
    country_code: 'KW',
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
    format: 'CI${amount}',
    precision: 2,
    country_code: 'KY',
  },
  {
    code: 'KZT',
    name: 'Kazakhstani Tenge',
    format: '₸{amount}',
    precision: 2,
    country_code: 'KZ',
  },
  {
    code: 'LAK',
    name: 'Lao Kip',
    format: '₭N{amount}',
    precision: 2,
    country_code: 'LA',
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    format: 'LL.{amount}',
    precision: 2,
    country_code: 'LB',
  },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
    format: 'Rs.{amount}',
    precision: 2,
    country_code: 'LK',
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
    format: 'L${amount}',
    precision: 2,
    country_code: 'LR',
  },
  {
    code: 'LSL',
    name: 'Lesotho Loti',
    format: 'L{amount}',
    precision: 2,
    country_code: 'LS',
  },
  {
    code: 'LYD',
    name: 'Libyan Dinar',
    format: 'LD{amount}',
    precision: 3,
    country_code: 'LY',
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    format: 'DH{amount}',
    precision: 2,
    country_code: 'MA',
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    format: 'L{amount}',
    precision: 2,
    country_code: 'MD',
  },
  {
    code: 'MGA',
    name: 'Malagasy Ariary',
    format: 'Ar{amount}',
    precision: 0,
    country_code: 'MG',
  },
  {
    code: 'MKD',
    name: 'Macedonian Denar',
    format: 'den{amount}',
    precision: 2,
    country_code: 'MK',
  },
  {
    code: 'MMK',
    name: 'Myanmar Kyat',
    format: 'Ks{amount}',
    precision: 2,
    country_code: 'MM',
  },
  {
    code: 'MNT',
    name: 'Mongolian Tögrög',
    format: '₮{amount}',
    precision: 2,
    country_code: 'MN',
  },
  {
    code: 'MOP',
    name: 'Macanese Pataca',
    format: 'MOP${amount}',
    precision: 2,
    country_code: 'MO',
  },
  {
    code: 'MRU',
    name: 'Mauritanian Ouguiya',
    format: 'UM{amount}',
    precision: 0,
    country_code: 'MR',
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
    format: 'Rs.{amount}',
    precision: 2,
    country_code: 'MU',
  },
  {
    code: 'MVR',
    name: 'Maldivian Rufiyaa',
    format: 'MRf{amount}',
    precision: 2,
    country_code: 'MV',
  },
  {
    code: 'MWK',
    name: 'Malawian Kwacha',
    format: 'MK{amount}',
    precision: 2,
    country_code: 'MW',
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    format: 'MX${amount}',
    precision: 2,
    country_code: 'MX',
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    format: 'RM{amount}',
    precision: 2,
    country_code: 'MY',
  },
  {
    code: 'MZN',
    name: 'Mozambican Metical',
    format: 'MTn{amount}',
    precision: 2,
    country_code: 'MZ',
  },
  {
    code: 'NAD',
    name: 'Namibian Dollar',
    format: 'N${amount}',
    precision: 2,
    country_code: 'NA',
  },
  {
    code: 'NGN',
    name: 'Nigerian Naira',
    format: '₦{amount}',
    precision: 2,
    country_code: 'NG',
  },
  {
    code: 'NIO',
    name: 'Nicaraguan Córdoba',
    format: 'C${amount}',
    precision: 2,
    country_code: 'NI',
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    format: 'kr{amount}',
    precision: 2,
    country_code: 'NO',
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
    format: 'Rs.{amount}',
    precision: 2,
    country_code: 'NP',
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    format: 'NZ${amount}',
    precision: 2,
    country_code: 'NZ',
  },
  {
    code: 'OMR',
    name: 'Omani Rial',
    format: 'OR{amount}',
    precision: 3,
    country_code: 'OM',
  },
  {
    code: 'PAB',
    name: 'Panamanian Balboa',
    format: 'B/.{amount}',
    precision: 2,
    country_code: 'PA',
  },
  {
    code: 'PEN',
    name: 'Peruvian Sol',
    format: 'S/.{amount}',
    precision: 2,
    country_code: 'PE',
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean Kina',
    format: 'K{amount}',
    precision: 2,
    country_code: 'PG',
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    format: '₱{amount}',
    precision: 2,
    country_code: 'PH',
  },
  {
    code: 'PKR',
    name: 'Pakistani Rupee',
    format: 'Rs.{amount}',
    precision: 2,
    country_code: 'PK',
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    format: 'zł{amount}',
    precision: 2,
    country_code: 'PL',
  },
  {
    code: 'PYG',
    name: 'Paraguayan Guaraní',
    format: '₲{amount}',
    precision: 2,
    country_code: 'PY',
  },
  {
    code: 'QAR',
    name: 'Qatari Riyal',
    format: 'QR{amount}',
    precision: 2,
    country_code: 'QA',
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    format: 'L{amount}',
    precision: 2,
    country_code: 'RO',
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    format: 'din{amount}',
    precision: 2,
    country_code: 'RS',
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    format: '₽{amount}',
    precision: 2,
    country_code: 'RU',
  },
  {
    code: 'RWF',
    name: 'Rwandan Franc',
    format: 'FRw{amount}',
    precision: 2,
    country_code: 'RW',
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    format: 'SR{amount}',
    precision: 2,
    country_code: 'SA',
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
    format: 'SI${amount}',
    precision: 2,
    country_code: 'SB',
  },
  {
    code: 'SCR',
    name: 'Seychellois Rupee',
    format: 'Rs.{amount}',
    precision: 2,
    country_code: 'SC',
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
    format: '£SD{amount}',
    precision: 2,
    country_code: 'SD',
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    format: 'kr{amount}',
    precision: 2,
    country_code: 'SE',
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    format: 'S${amount}',
    precision: 2,
    country_code: 'SG',
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
    format: '£{amount}',
    precision: 2,
    country_code: 'SH',
  },
  {
    code: 'SLL',
    name: 'Sierra Leonean Leone',
    format: 'Le{amount}',
    precision: 2,
    country_code: 'SL',
  },
  {
    code: 'SLS',
    name: 'Somaliland Shilling',
    format: 'Sl{amount}',
    precision: 2,
    country_code: 'SL',
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
    format: 'Sh.So.{amount}',
    precision: 2,
    country_code: 'SO',
  },
  {
    code: 'SRD',
    name: 'Surinamese Dollar',
    format: 'Sr${amount}',
    precision: 2,
    country_code: 'SR',
  },
  {
    code: 'SSP',
    name: 'South Sudanese Pound',
    format: 'SS£{amount}',
    precision: 2,
    country_code: 'SS',
  },
  {
    code: 'STN',
    name: 'Sao Tome and Príncipe Dobra',
    format: 'Db{amount}',
    precision: 2,
    country_code: 'ST',
  },
  {
    code: 'SVC',
    name: 'Salvadoran Colón',
    format: '₡{amount}',
    precision: 2,
    country_code: 'SV',
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
    format: 'LS{amount}',
    precision: 2,
    country_code: 'SY',
  },
  {
    code: 'SZL',
    name: 'Swazi Lilangeni',
    format: 'L{amount}',
    precision: 2,
    country_code: 'SZ',
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    format: '฿{amount}',
    precision: 2,
    country_code: 'TH',
  },
  {
    code: 'TJS',
    name: 'Tajikistani Somoni',
    format: 'ЅМ{amount}',
    precision: 2,
    country_code: 'TJ',
  },
  {
    code: 'TMT',
    name: 'Turkmenistan Manat',
    format: 'TMT{amount}',
    precision: 2,
    country_code: 'TM',
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    format: 'د.ت{amount}',
    precision: 3,
    country_code: 'TN',
  },
  {
    code: 'TOP',
    name: 'Tongan Paʻanga',
    format: 'T${amount}',
    precision: 2,
    country_code: 'TO',
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    format: '₺{amount}',
    precision: 2,
    country_code: 'TR',
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    format: 'TT${amount}',
    precision: 2,
    country_code: 'TT',
  },
  {
    code: 'TVD',
    name: 'Tuvaluan Dollar',
    format: '${amount}',
    precision: 2,
    country_code: 'TV',
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    format: 'NT${amount}',
    precision: 2,
    country_code: 'TW',
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    format: 'TSh{amount}',
    precision: 2,
    country_code: 'TZ',
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
    format: '₴{amount}',
    precision: 2,
    country_code: 'UA',
  },
  {
    code: 'UGX',
    name: 'Ugandan Shilling',
    format: 'USh{amount}',
    precision: 0,
    country_code: 'UG',
  },
  {
    code: 'USD',
    name: 'United States Dollar',
    format: '${amount}',
    precision: 2,
    country_code: 'US',
  },
  {
    code: 'UYU',
    name: 'Uruguayan Peso',
    format: '$U{amount}',
    precision: 2,
    country_code: 'UY',
  },
  {
    code: 'UZS',
    name: 'Uzbekistani Som',
    format: 'сўм{amount}',
    precision: 2,
    country_code: 'UZ',
  },
  {
    code: 'VED',
    name: 'Venezuelan Digital Bolívar',
    format: 'Bs.D{amount}',
    precision: 2,
    country_code: 'VE',
  },
  {
    code: 'VES',
    name: 'Venezuelan Bolívar Soberano',
    format: 'Bs.S{amount}',
    precision: 2,
    country_code: 'VE',
  },
  {
    code: 'VND',
    name: 'Vietnamese Dong',
    format: '₫{amount}',
    precision: 0,
    country_code: 'VN',
  },
  {
    code: 'VUV',
    name: 'Vanuatu Vatu',
    format: 'VT{amount}',
    precision: 0,
    country_code: 'VU',
  },
  {
    code: 'WST',
    name: 'Samoan Tala',
    format: 'WS${amount}',
    precision: 2,
    country_code: 'WS',
  },
  {
    code: 'XAF',
    name: 'Central African CFA Franc',
    format: 'FCFA{amount}',
    precision: 0,
    country_code: 'CF',
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    format: 'EC${amount}',
    precision: 2,
    country_code: 'XC',
  },
  {
    code: 'XOF',
    name: 'West African CFA Franc',
    format: 'CFA{amount}',
    precision: 0,
    country_code: 'CI',
  },
  {
    code: 'XPF',
    name: 'CFP Franc',
    format: 'CFPF{amount}',
    precision: 0,
    country_code: 'PF',
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    format: 'ر.ي{amount}',
    precision: 2,
    country_code: 'YE',
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    format: 'R{amount}',
    precision: 2,
    country_code: 'ZA',
  },
  {
    code: 'ZMW',
    name: 'Zambian Kwacha',
    format: 'K{amount}',
    precision: 2,
    country_code: 'ZM',
  },
  {
    code: 'ZWB',
    name: 'RTGS Dollar',
    format: 'RTGS${amount}',
    precision: 2,
    country_code: 'ZW',
  },
  {
    code: 'ZWL',
    name: 'Zimbabwean Dollar',
    format: 'ZWL${amount}',
    precision: 2,
    country_code: 'ZW',
  },
]
