import { tagTypes } from '@/redux/api/TagTypes'
import { apiSlice } from '@/redux/api/apiSlice'

export const plansApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Get   Plans
    getPlans: builder.query({
      query: () => {
        return `/v2/plans`
      },
      transformResponse: (response, meta, arg) => {
        const subscribed_plan = response?.plans?.find((p) => p.subscribed)
        return {
          plans: response?.plans ?? [],
          old_plan: response?.old_plan ?? [],
          subscribed_plan: subscribed_plan ?? {},
          subscribed_plan_name: subscribed_plan?.name ?? '',
        }
      },
      providesTags: [tagTypes.plans],
    }),
  }),
})

export const { useGetPlansQuery } = plansApi
