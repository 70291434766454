import { tradly_api_call } from '@/WebHandler/tradly_api_call'
import HomeMain from '@/components/home/HomeMain'
import { useGetPlansQuery } from '@/redux/features/plans/plansApi'
import React from 'react'

const Home = ({ templates }) => {
  const { plans } = useGetPlansQuery()
  return <HomeMain templates={templates} />
}

export default Home

// export async function getServerSideProps({ params, req, res, query }) {
//   res.setHeader('Cache-Control', 'public, s-maxage=100, stale-while-revalidate=259')

// }

//
export async function getStaticProps({ params, req, res, query }) {
  let params_data = {
    page: 1,
    category_id: 4045,
    type: 'listings',
    include: 'attributes',
    status: 2,
    sort: 'oldest_first',
  }

  const templates = await tradly_api_call({
    method: 'GET',
    path: '/products/v1/listings',
    params: params_data,
    onSuccess: (res) => {
      return res?.listings
    },
    onError: (err) => {
      console.log(err)
      return {}
    },
  })

  return {
    props: { templates: templates?.listings }, // will be passed to the page component as props
  }
}
